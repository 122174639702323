<template>
<div class="col-12">
    <div class="grid-area mb-25">
        <div class="card">
            <div class="card-body">
                <div class="w-100 text-center">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col col-12 col-sm-6 col-md-6 col-lg-3">
                                <div class="col-md-12 col-lg-12 mt-5">
                                    <span class="text-danger font-weight-bold" style='font-size: 1.5rem'>{{ t('prize1st',{},{locale:getLocal}) }}</span><br><span class="font-weight-bold" style='font-size: 1.2rem'>{{ t('prizemoney',{},{locale:getLocal}) }} 6,000,000 {{ t('bath',{},{locale:getLocal}) }}</span>
                                </div>
                                <div class="col-md-12 col-lg-12 mt-4">
                                    <span class="text-danger font-weight-bold" style='font-size: 2rem'>{{ dataShow.first_prize[0] }}</span>
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-md-6 col-lg-3">
                                <div class="col-md-12 col-lg-12 mt-5">
                                    <span class="text-danger font-weight-bold" style='font-size: 1.3rem'>{{ t('prize3digitf',{},{locale:getLocal}) }}</span><br><span class="font-weight-bold" style='font-size: 1rem'>{{ t('prizemoney',{},{locale:getLocal}) }} 4,000 {{ t('bath',{},{locale:getLocal}) }}</span>
                                </div>
                                <div class="col-md-12 col-lg-12 mt-4">
                                    <span class='col font-weight-bold' style='font-size: 1.8rem'>{{ dataShow.first_three_digit_prize[0] }}</span> <span class='col font-weight-bold' style='font-size: 1.8rem'>{{ dataShow.first_three_digit_prize[1] }}</span>
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-md-6 col-lg-3">
                                <div class="col-md-12 col-lg-12 mt-5">
                                    <span class="text-danger font-weight-bold" style='font-size: 1.3rem'>{{ t('prize3digitb',{},{locale:getLocal}) }}</span><br><span class="font-weight-bold" style='font-size: 1rem'>{{ t('prizemoney',{},{locale:getLocal}) }} 4,000 {{ t('bath',{},{locale:getLocal}) }}</span>
                                </div>
                                <div class="col-md-12 col-lg-12 mt-4">
                                    <span class='col font-weight-bold' style='font-size: 1.8rem'>{{ dataShow.last_three_digit_prize[0] }}</span> <span class='col font-weight-bold' style='font-size: 1.8rem'>{{ dataShow.last_three_digit_prize[1] }}</span>
                                </div>
                            </div>
                            <div class="col col-12 col-sm-6 col-md-6 col-lg-3">
                                <div class="col-md-12 col-lg-12 mt-5">
                                    <span class="text-danger font-weight-bold" style='font-size: 1.3rem'>{{ t('prize2digitb',{},{locale:getLocal}) }}</span><br><span class="font-weight-bold" style='font-size: 1rem'>{{ t('prizemoney',{},{locale:getLocal}) }} 2,000 {{ t('bath',{},{locale:getLocal}) }}</span>
                                </div>
                                <div class="col-md-12 col-lg-12 mt-4">
                                    <span class='col font-weight-bold' style='font-size: 1.8rem'>{{ dataShow.last_two_digit_prize[0] }}</span>
                                </div>
                            </div>
                        </div><hr style='margin: 2rem auto'>
                        <div class="row">
                            <div class="col col-12 col-md-12 text-center">
                                <span class="text-danger font-weight-bold" style='font-size: 1.3rem'>{{ t('similarprize1st',{},{locale:getLocal}) }}</span><br><span style='font-size: 1rem'>{{ t('prizemoney',{},{locale:getLocal}) }} 100,000 {{ t('bath',{},{locale:getLocal}) }}</span>
                            </div>
                            <div class="col col-12 col-md">
                                <span class='font-weight-bold' style='font-size: 2rem'>{{ dataShow.similar_to_first_prize[0] }}</span>
                            </div>
                            <div class="col col-12 col-md">
                                <span class='font-weight-bold' style='font-size: 2rem'>{{ dataShow.similar_to_first_prize[1] }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    useI18n
} from 'vue-i18n'
export default {
    name: 'VerifyLottoR1',
    setup() {
        const {
            t,
            locale
        } = useI18n();
        return {
            t,
            locale
        };
    },
    data() {
        return {}
    },
    props: [
        "dataShow"
    ],
    methods: {

    },
    computed: {
        getLocal: function () {
            return this.$store.getters['display/getLocal']
        },
    },

}
</script>

<style>

</style>
