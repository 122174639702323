<template>
<section class="h-100">
    <div class="card card-default card-md h-100">
        <div class="card-body">
            <div class="form-group mb-0">
                <input type="text" class="form-control form-control-lg" :placeholder="t('verifyLottoInputPlaceHolder')" v-model="textUrl" />
            </div><br>
            <div class="form-group mb-0">
                <span class='float-right'>
                    <div class="action-btn">
                        <a @click="verify()" class="btn btn-sm btn-primary btn-add" style="color:white;">
                            <i class="fas fa-search"></i> {{ t('verifyLottoSearchButton') }}</a>
                    </div>
                </span>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import {
    useI18n
} from 'vue-i18n';
import axios from 'axios'
export default {
    name: "VerifyLottoSearch",
    setup() {
        const {
            t,
            locale
        } = useI18n();
        return {
            t,
            locale,
        };
    },
    data: function () {
        return {
            textUrl: "",
        };
    },
    methods: {
        async verify() {
            try {
                this.$emit('clearDataShow')
                let linkUrl = this.textUrl;
                if (linkUrl == '') throw new Error(serviceMain.getTxtLocale(this, 'verifyLottoInputPlaceHolder'));

                if (linkUrl.toLowerCase().indexOf('sanook') == -1) throw new Error(serviceMain.getTxtLocale(this, 'verifyLottoInputPlaceHolder'));

                let data = {
                    URL: this.textUrl
                }

                axios.post('https://lotto-result.ecotechpart.com/api/webscraping', data).then(res => {
                    if (res.data.data.fifth_prize.length == 0) {
                        throw new Error(serviceMain.getTxtLocale(this, 'verifyLottoInputPlaceHolder'))
                    }
                    this.$emit('getDataShow', res.data.data);
                }).catch(error => {
                    serviceMain.showErrorAlert(this, error)
                })
            } catch (error) {
                serviceMain.showErrorAlert(this, error)
            }
        },
    },
    computed: {
        getLocal: function () {
            return this.$store.getters["display/getLocal"];
        }
    },
    watch: {
        // getLocal: function () {
        //     i18n.locale = this.getLocal;
        // }
    }
};
</script>

<style>
</style>
