<template>
    <div class="col-12">
        <div class="grid-area mb-25">
            <div class="card text-center">
                <div class="card-header">
                    <span class="text-danger font-weight-bold mx-auto my-3" style='font-size: 1.1rem'>{{ t(`${getRank.rank}`,{},{locale:getLocal}) }} - {{ getRank.rewardNo }} {{ t(`prize`,{},{locale:getLocal}) }} <br>({{ getRank.rewardPrice }} {{ t('bath',{},{locale:getLocal}) }})</span>
                </div>
                <div class="card-body">
                    <div class="w-100">
                        <div class="bd-example">
                            <div class="col-12 d-none d-md-block">
                                <div class="evenOdd row" v-for="(e,index) in getDataShow" :key="index">
                                    <div class="col col-6 col-md-3 " v-for="(e1,index) in e" :key="index">
                                        <span style='font-size: 1.3rem'>{{ e1 }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 d-md-none">
                                <div class="evenOdd row" v-for="(e,index) in getDataShowM" :key="index">
                                    <div class="col col-6 col-md-3 " v-for="(e1,index) in e" :key="index">
                                        <span style='font-size: 1.3rem'>{{ e1 }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
    name:'VerifyLottoR3',
    setup() {
        const {
            t,
            locale
        } = useI18n();
        return {
            t,
            locale
        };
    },
    data() {
        return {

        }
    },
    props:[
        "dataShow",
        "rank"
    ],
    methods: {
        
    },
    computed: {
        getLocal: function () {
            return this.$store.getters['display/getLocal']
        },
        getRank: function () {
            return this.rank == 4 ? {
                rank: 'prize4th',
                rewardNo: '50',
                rewardPrice: '40,000'
            }:
            {
                rank: 'prize5th',
                rewardNo: '100',
                rewardPrice: '20,000'
            }
        },
        getDataShow: function () {
            let sData = [];
            let con = 0;
            let index = 0;
            let temp = [];
            for (let i = 0; i < this.dataShow.length; i++) {
                temp[con] = this.dataShow[i]
                con ++;
                if(con == 4 || i == this.dataShow.length-1){
                    sData[index] = temp;
                    con = 0;
                    index ++;
                    temp = [];
                }
            }
            return sData
        },
        getDataShowM: function () {
            let sData = [];
            let con = 0;
            let index = 0;
            let temp = [];
            for (let i = 0; i < this.dataShow.length; i++) {
                temp[con] = this.dataShow[i]
                con ++;
                if(con == 2 || i == this.dataShow.length-1){
                    sData[index] = temp;
                    con = 0;
                    index ++;
                    temp = [];
                }
            }
            return sData
        },
    },


}
</script>

<style>
 .evenOdd:nth-child(2n-1){background-color: rgb(228, 228, 228);} 
</style>