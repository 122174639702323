<template>
<div :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`">
    <div class="container-fluid">
        <div class="row">
            <div class="col col-lg-12">
                <Breadcrumb :options="options" />
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-6 col-xxl-4 mb-4">
                <VerifyLottoSearch @clearDataShow="clearDataShow" @getDataShow="getDataShow"/>
            </div>
            <div class="col-12 col-lg-6 col-xxl-4 mb-4">
                <VerifyLottoListDraw :dataShow="dataShow" :isShowData="isShowData" :isVerifyData="isVerifyData"/>
            </div>
        </div>
        <div v-if="isShowData">
            <div class="row">
                <div class="col col-md-12 p-0">
                    <VerifyLottoR1 :dataShow="dataShow" />
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12 p-0">
                    <VerifyLottoR2 :dataShow="dataShow2" :rank="2" :key="rank2" />
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12 p-0">
                    <VerifyLottoR2 :dataShow="dataShow3" :rank="3" :key="rank3" />
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12 p-0">
                    <VerifyLottoR3 :dataShow="dataShow4" :rank="4" :key="rank4" />
                </div>
            </div>
            <div class="row">
                <div class="col col-md-12 p-0">
                    <VerifyLottoR3 :dataShow="dataShow5" :rank="5" :key="rank5" />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import VerifyLottoSearch from "@/components/VerifyLotto/VerifyLottoSearch.vue";
import VerifyLottoListDraw from "@/components/VerifyLotto/VerifyLottoListDraw.vue";
import VerifyLottoR1 from "@/components/VerifyLotto/VerifyLottoR1.vue";
import VerifyLottoR2 from "@/components/VerifyLotto/VerifyLottoR2.vue";
import VerifyLottoR3 from "@/components/VerifyLotto/VerifyLottoR3.vue";
export default {
    name: "VerifyLotto",
    components: {
        Breadcrumb,
        VerifyLottoSearch,
        VerifyLottoListDraw,
        VerifyLottoR1,
        VerifyLottoR2,
        VerifyLottoR3,
    },
    data: function () {
        return {
            options: {
                pageKey: "verifyLotto"
            },
            isShowData: false,
            isVerifyData: false,
            dataShow: {},
            dataShow2: [],
            dataShow3: [],
            dataShow4: [],
            dataShow5: [],
        };
    },
    mounted() {

    },
    methods: {
        getDataShow(data) {
          this.dataShow = data;
          this.dataShow2 = data.second_prize;
          this.dataShow3 = data.third_prize;
          this.dataShow4 = data.fourth_prize;
          this.dataShow5 = data.fifth_prize;

          this.isShowData = true;
          this.isVerifyData = true;
        },
        clearDataShow() {
          this.isShowData = false;
          this.isVerifyData = false;
        }
    }
};
</script>

<style>
</style>
