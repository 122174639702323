<template>
<section class="h-100">
    <div class="card card-default card-md h-100">
        <div class="card-body">
            <div class="form-group mb-0">
                <input type="text" class="form-control form-control-lg" :placeholder="t('verifyLottoDrawPlaceHolder')" v-model="textDrawId" />
            </div><br>
            <div class="form-group mb-0">
                <span class='float-right col-12'>
                    <div class="row">
                        <div class=" col-12 col-md-6 px-1">
                            <div class="action-btn">
                                <a @click="updateResult()" class="btn btn-sm btn-primary btn-add w-100" style="color:white;">
                                    <i class="fas fa-trophy"></i>{{ t('updateresult') }}</a>
                            </div>
                        </div>
                        <div class=" col-12 col-md-6 px-1">
                            <div class="action-btn">
                                <a @click="checkResult()" class="btn btn-sm btn-primary btn-add w-100" style="color:white;">
                                    <i class="fas fa-check-square"></i> {{ t('checkresult') }}</a>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import * as serviceAPI from '@/services/API.service';
import * as serviceMain from '@/services/main.service';
import {
    useI18n
} from 'vue-i18n';
export default {
    name: "VerifyLottoListDraw",
    setup() {
        const {
            t,
            locale
        } = useI18n();
        return {
            t,
            locale,
        };
    },
    data: function () {
        return {
            textDrawId: "",
            isUpdated: false
        };
    },
    props: [
        'dataShow',
        'isShowData',
        'isVerifyData'
    ],
    mounted() {
        
    },
    methods: {
        async checkResult() {
            try {
                if (this.isShowData == false) {
                    throw new Error(serviceMain.getTxtLocale(this, 'pleaseverifylottofirst'));
                }

                if (this.textDrawId.trim() == '') {
                    throw new Error(serviceMain.getTxtLocale(this, 'pleaseinputdrawid'));
                }

                if(!this.isUpdated) {
                    throw new Error(serviceMain.getTxtLocale(this, 'pleaseupdateresultfirst'));
                }

                let data = {
                    drawId: this.textDrawId
                }
                let getAPI = await serviceAPI.call_API('post', 'lotto/api/v1/startCheckLotteryResult', data, 'auth');
                let getData = getAPI.status.messageTH;
                if (getAPI.status.code == '0000') {
                    this.textDrawId = '';
                    serviceMain.showSuccessAlert(this, getData)
                } else {
                    serviceMain.showErrorAlert(this, getData)
                }

            } catch (error) {
                serviceMain.showErrorAlert(this, error)
            }
        },
        async updateResult() {
            try {
                if (this.isShowData == false) {
                    throw new Error(serviceMain.getTxtLocale(this, 'pleaseverifylottofirst'));
                }

                if (this.textDrawId.trim() == '') {
                    throw new Error(serviceMain.getTxtLocale(this, 'pleaseinputdrawid'));
                }

                let data = {
                    "drawId": this.textDrawId,
                    "lotteryResults": this.dataShow
                }
                let getAPI = await serviceAPI.call_API('post', 'lotto/api/v1/updateLotteryResults', data, 'auth');
                let getData = getAPI.status.messageTH;
                if (getAPI.status.code == '0000') {
                    this.isUpdated = true;
                    serviceMain.showSuccessAlert(this, getData)
                } else {
                    serviceMain.showErrorAlert(this, getData)
                }
            } catch (error) {
                serviceMain.showErrorAlert(this, error)
            }
        },
    },
    computed: {
        // getLocal: function () {
        //     return this.$store.getters["display/getLocal"];
        // }
    },
    watch: {
        isVerifyData: function(newVal, oldVal){
            if(newVal) {
                this.isUpdated = false;
                this.textDrawId = '';
            }
        }
    }
};
</script>

<style>
</style>
