<template>
    <div class="col-12">
        <div class="grid-area mb-25">
            <div class="card text-center">
                <div class="card-header">
                    <span class="text-danger font-weight-bold mx-auto my-3" style='font-size: 1.1rem'>{{ t(`${getRank.rank}`,{},{locale:getLocal}) }} - {{ getRank.rewardNo }} {{ t(`prize`,{},{locale:getLocal}) }} <br>({{ getRank.rewardPrice }} {{ t('bath',{},{locale:getLocal}) }})</span>
                </div>
                <div class="card-body">
                    <div class="w-100">

                        <div class="bd-example">
                            <div class="col">
                                <div class="row">
                                    <div class="col col-6 col-md-3" v-for="(e, index) in dataShow" :key="index">
                                        <span style='font-size: 1.3rem'>{{ e }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
    name:'VerifyLottoR2',
    setup() {
        const {
            t,
            locale
        } = useI18n();
        return {
            t,
            locale
        };
    },
    data() {
        return {
            
        }
    },
    props:[
        "dataShow",
        "rank"
    ],
    methods: {
        
    },
    computed: {
        getLocal: function () {
            return this.$store.getters['display/getLocal']
        },
        getRank: function () {
            return this.rank == 2 ? {
                rank: 'prize2nd',
                rewardNo: '5',
                rewardPrice: '200,000'
            }:
            {
                rank: 'prize3rd',
                rewardNo: '10',
                rewardPrice: '80,000'
            }
        }
    },


}
</script>

<style>

</style>